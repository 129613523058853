<template>
  <component :is="expenseData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="expenseData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.expenses.expensesNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-expense-list' }">
          {{ $t("message.expenses.expenseList") }}
        </b-link>
        {{ $t("message.expenses.forOtherExpense") }}
      </div>
    </b-alert>

    <template v-if="expenseData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.expenses.editExpense") }}</h2>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form @submit.prevent="handleSubmit(updateExpense())">
              <b-row class="mb-2">
                <b-col md="8">
                  <b-form-group label-for="paymentTypeExpenses">
                    <template v-slot:label>
                      {{ $t("message.expenses.paymentMethod") }}
                    </template>
                    <v-select
                      v-model="expenseData.paymentType"
                      :options="paymentOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="type-status"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label-for="valueExpenses">
                    <template v-slot:label>
                      {{ $t("message.expenses.value") }}
                    </template>
                    <b-form-input
                      v-money="getCurrencyFormat()"
                      v-model="expenseData.value"
                      class="mt-0"
                      label="value"
                      label-for="valueExpenses"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label-for="dateExpenses">
                    <template v-slot:label>
                      {{ $t("message.bills.expiryDate") }}
                    </template>
                    <flat-pickr
                      id="date-expenses"
                      v-model="expenseData.date"
                      class="mt-0 form-control"
                      :config="flatConfig"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="mb-0">
                  <validation-provider name="description" rules="required">
                    <b-form-group label-for="descriptionExpenses">
                      <template v-slot:label>
                        {{ $t("message.projects.description") }}
                      </template>
                      <quill-editor
                        id="title"
                        style="margin-bottom: 52px; height: 200px"
                        :options="editorOption"
                        v-model="expenseData.description"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="12">
                  <b-form-group l label-for="typeExpenses">
                    <template v-slot:label>
                      {{ $t("message.tasks.type") }}
                    </template>
                    <v-select
                      v-model="expenseData.type"
                      :options="typeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="payment-status"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateExpense()"
        :to="{ name: 'apps-expense-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    flatPickr,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      value: 0,
      //Select
      paymentOptions: [],
      typeOptions: [],

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      userData: store.state.user.userData,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  async created() {
    var paymentList = [];
    await axios
      .get(`${URL_API}expense_payment`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          paymentList.push(element);
          this.paymentOptions.push({
            label: paymentList[i],
            value: paymentList[i],
          });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}expense_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.typeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    updateExpense() {
      if (this.$i18n.locale === "en") {
        this.expenseData.value = Number(
          this.expenseData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.expenseData.value = this.switchDotsAndCommas(
          this.expenseData.value
        );
        this.expenseData.value = Number(
          this.expenseData.value.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      axios({
        method: "put",
        url: `${URL_API}expense/${this.expenseData.id}`,
        headers: getHeader(this.userData),
        data: this.expenseData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },
  },

  setup() {
    const expenseData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}expense/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        expenseData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          expenseData.value = undefined;
        }
      });

    return {
      expenseData,
      vSelect,
    };
  },
};
</script>
